.login-wrapper {
    max-width: 505px;
    max-height: 687px;
}

.login-box {
    background-color: #FFF;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
}

.logo {
    width: 100px;
    height: auto;
    margin-bottom: 10px;
}

.title {
    font-size: 24px;
    font-family: "roboto";
}

@media(max-width: 280px) {
    .title {
        font-size: 18px;
        font-family: "roboto";
    }
}

@media(min-width: 400px) {
    .title {
        font-size: 28px;
        font-family: "roboto";
    }
}
@media(min-width: 800px) {
    .title {
        font-size: 32px;
        font-family: "roboto";
    }
}