.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.divExit {
  margin: auto;
  margin-right: 0px;
}

.menuHeader {
  min-width: 400px;
}

.contentHolder {
  background-color: #FFF;
  max-width: 1500px;
  border: #000 solid;
  border-radius: 7px;
  border-width: 0.5px;
  margin: auto;
}

.topControls {
  padding: 10px;
  margin: auto;
  text-align: center;
}

.searchInput {
  width: 700px;
}

.searchButton {
  margin-left: 15px;
}

.siteHeader {
  background-color: "#400CCC";
  padding-right: 10px;
  height: 70px;
}

.pageTitle {
  text-align: center;
  padding-top: 30px;
}

.buttonGroup {
  padding: 30px 5px 5px 5px;
  min-width: 150px;
}

.buttonGroup > button {
  margin: auto 5px;
}

.MuiGrid-root {
  margin-top: auto;
  padding: 15px;
}

.formInput {
  width: 500px;
}

.tableCrudHeader > tr > th {
  background-color: lightgoldenrodyellow;
}