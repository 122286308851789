.userMessage {
    text-align: center;
    margin: auto;
}

.userMessageHidden {
    visibility: hidden;
}

.userMessageContainer {
    width: 100%;
}